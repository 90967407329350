import { Props, Slots } from "vue-loading-overlay";

/**
* Loading Configuration
* https://www.npmjs.com/package/vue-loading-overlay?activeTab=readme
* configure loading theme here (color, shape, background)
*/
export const loadingConfiguration: {
    props: Props,
    slots: Slots,
} = {
    props: {        
    },
    slots: {
    }
}