import { MODE } from "@/vue/composables/app/use-modes";
import { ALL } from "dns";

export default {
    CHANGE_TOOL_LANGUAGE: "Changer la langue de l'outil",
    CANCEL: "Annuler",
    HELLO: "Bonjour %{name}",
    HOME: "Accueil",
    EMAIL: "Email",
    TAGS: "Etiquettes",
    LANGUAGES: "Langues des écrans",
    MODES: "Modes",
    SUBMIT: "Envoyer",
    LASTNAME: "Nom",
    FIRSTNAME: "Prénom",
    PASSWORD: "Mot de passe",
    DELETE: "Supprimer",
    WELCOME: "Bienvenue !",
    WELCOME_P1:
        "L'outil <strong>SMART SCREEN</strong> vous permet de rechercher et télécharger les écrans de l'app <strong>Home + Control</strong> pour vos différents supports de communication.",
    WELCOME_P2:
        'Pour vous connecter rien de plus simple, ciquez sur le bouton "Connexion avec mon compte Legrand/Bticino" puis il vous suffit d\'utiliser votre identifiant et votre mot de passe <strong>Legrand ou Bticino.</strong>',
    ARE_YOU_SUR_DELETE: "Êtes-vous sûr de vouloir supprimer ?",
    LOG_WITH_LG: "Connexion avec mon compte Legrand/Bticino",
    NOT_LOGGED_IN: "Non identifié ?",
    LOGIN: "connexion",
    REGISTER: "S'enregistrer",
    FR: "Français",
    EN: "English",
    OR: "Ou",
    UNLOCK_ADMIN_MODE: "Débloquer le mode administrateur",
    ADMIN_MODE_PASSWORD: "Mot de passe administrateur",
    Login: "Connexion",
    QUIT_ADMIN: "QUITTER ADMIN", 
    ADMIN_MODE_ACTIVATED: "Mode administrateur activé",
    ADMIN_MODE_ERROR: "Erreur de mode administrateur",
    ADMIN_MODE_DEACTIVATED: "Mode administrateur désactivé",
    ERRORS: {
        BAD_REQUEST: "Elements transmis invalides",
        NOT_AUTHENTICATED: "Vous n'etes pas authentifie",
        FORBIDDEN: "Acces interdit",
        NOT_FOUND: "Element introuvable",
        METHOD_NOT_ALLOWED: "Methode non autorise",
        CONFLICT: "Cette valeur n'est pas unique : ",
        GENERAL_ERROR: "Erreur Inconnue",
        MISSING: "Ce champ est requis",
        INVALID_LOGIN: "Identifiants incorrects",
        PASSWORD_LENGTH: "Le mot de passe doit contenir au moins 8 caractères",
        EMAIL: "Adresse mail invalide",
        LONG: "Trop long",
        SHORT: "Trop court",
    },
    ROUTES: {
        BACK: "Retour en arrière",
        ERRORS: {
            401: "Vous n'êtes pas authentifié.",
            403: "Vous n'avez pas la permission d'accéder à cette page.",
            404: "La page que vous recherchez n'existe pas.",
        },
        HOME: "Accueil",
    },
    LOGOUT: "Déconnexion",
    CLOSE: "Fermer",
    PERIODE: "Période",
    DETAILS: {
        TYPE: "Type",
        FILE: "Fichier",
        WEIGHT: "Taille",
        RESOLUTION: "Résolution",
        CREATION_DATE: "Crée le",
        MODIFICATION_DATE: "Modifié le",
        DOWNLOAD: "Télécharger",
        SELECT: "Sélectionner",
    },
    SEARCH: "Rechercher...",
    SCREENS: "écrans",
    CHANGE_LANGUAGE: "Changer de langue",
    NAME_ASC: "Nom (A-Z)",
    NAME_DESC: "Nom (Z-A)",
    CREATION_ASC: "Création",
    CREATION_DESC: "Création (inversé)",
    EDITION_ASC: "Modification",
    EDITION_DESC: "Modification (inversé)",
    CTRL_CLICK_EXPL:
        "Pour télécharger les écrans dans une langue, sélectionner la langue souhaitée. Pour télécharger les écrans dans plusieurs langues simultanément, sélectionner les langues souhaitées en maintenant la touche CTRL appuyée.",
    SELECT_ALL: "Tout cocher",
    DESELECT_ALL: "Tout décocher",
    KPI: "KPI",
    TOTAL: "Total",
    TOTAL_CONNECTIONS: "Total des connexions",
    NB_OF_VIEWED_SCREENS: "Nombre d'écrans vus",
    NB_OF_INPRINT_SCREENS: "Nombre d'impressions",
    NB_OF_DOWNLOADED_SCREENS: "Nombre d'écrans téléchargés",
    LANGUAGES_OF_DOWNLOADED_SCREENS: "Langues des écrans téléchargés",
    PARAMETERS: "Paramètres",
    CONNECTIONS: "Connexions",
    DELETE_FILE: "Supprimer le fichier",
    FILE_DELETED: "Fichier supprimé",
    DELETE_FILE_CONFIRM: "Êtes-vous sûr de vouloir supprimer ce fichier ?",
    FROM: "Du",
    TO: "Au",
    ALL: "Tous",
    LIGHT: "Light",
    DARK: "Dark",
    MODE: "Mode",
    ON_DATERANGE: "Sur la période",
    INDEPENDANT_OF_DATERANGE: "Indépendamment de la période",
    MOST_POPULAR_SCREENS: "Ecrans les plus populaires",
    VIEWED: "Vus",
    DOWNLOADED: "Téléchargés",
    CODE_ISO: "Code ISO",
    LANGUAGE: "Langue",
    DOWNLOADS: "Téléchargements",
    SDTABLE: {
        NO_DATA: "Pas de données",
    },
    SCREEN: "Ecran",
    NB_VIEWED: "Nombre de vues",
    NB_DOWNLOADED: "Nombre de téléchargements",
};
