import { createGlobalState } from "@vueuse/core";
import _ from "lodash";
import { ref } from "vue";
import { useTags } from "./use-tags";



export const useSearch = createGlobalState(() => {

    const currentSearch = ref<string>("");
    const { currentsTagSlugs: slugs } = useTags();

    const empty = () => {
        return _.isEmpty(currentSearch.value) && slugs.value.length === 0;
    }

    const match = (value: string) => {
        return empty() || (!_.isEmpty(currentSearch.value) && value.toLowerCase().includes(currentSearch.value.toLowerCase())) || slugs.value.some((tag) => value.toLowerCase().includes(tag.toLowerCase()));
    };


    return {
        slugs,
        currentSearch,
        search: {
            empty,
            match,
        }
    };
});