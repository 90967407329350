export default {
	themes: {
		light: {
			dark: false,
			colors: {
				primary: "#4E77F1",
				secondary: "#048ba8",
				third: "#99C24D",
				darker: "#2E4057",
				black: "#292929",
				red: "#CC0000",
			},
		},
	},
};
