import { RouteRecordRaw } from 'vue-router';

const methodsKeywords = 'patch|post|delete';
const modeKeywords = 'edit|view|create';

export const routes: RouteRecordRaw[] = [
    {
        path: '/',
        name: 'home',
        component: () => import(/* webpackChunkName: "Form" */ '../vue/Page/Login/LoginPage.vue')
    },
    {
        path: '/login',
        name: 'login',
        component: () => import(/* webpackChunkName: "Form" */ '../vue/Page/Login/LoginPage.vue')
    },
    {
        path: '/file-manager',
        name: 'fileManager',
        component: () => import(/* webpackChunkName: "FileManager" */ '../vue/Page/FileManager/FileManagerPage.vue')
    },
    {
        path: '/kpi',
        name: 'kpi',
        component: () => import(/* webpackChunkName: "FileManager" */ '../vue/Page/KPI/KPI.vue')
    },
    // {
    //     path: '/oauth/adfs',
    //     name: 'adfs',
    //     component: () => import(/* webpackChunkName: "FileManager" */ '../vue/Page/Login/LoginPage.vue')
    // },
    // {
    //     path: '/:pathMatch(.*)*',
    //     name: '404',
    //     component: () => import(/* webpackChunkName: "FileManager" */ '../vue/Page/Errors/404-not-found.vue')
    // }
]