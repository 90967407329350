import flatted from "flatted";

export function isSSR(): boolean {
	return typeof window === "undefined";
}

export function getStringLength(item: any): number {
	if (typeof item === "object") {
		return flatted.stringify(item).length;
	} else {
		return item.length;
	}
}
