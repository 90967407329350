import { isSSR } from "@/helpers";
import { ISSRContext } from "@/models/app/core/ssr-context.interface";
import _ from "lodash";
import { useI18n } from "vue-i18n";
import { bundleSSRContext } from "../server/bundle-app";
import { retrieveStore } from "../server/retrieve-store";
import { useSessionStorage } from "../server/use-session-storage";


/**
 * override {@link useI18n } to automatically set the current session's locale, base on user preference  
 * @returns the default {@link useI18n} return values
 */
export function useLocale() {
  const { locale, fallbackLocale } = useI18n();

  reload();

  function reload() {
    // if on server, check in the session
    // check in the store on client
    if (isSSR()) {
      const ctx: ISSRContext = bundleSSRContext();
      const { get } = useSessionStorage(ctx.req);
      const sessionLocale = get('locale') || fallbackLocale.value;

      // set the app locale from the session locale
      locale.value = sessionLocale;
    } else {
      // get locale value form the store
      // const { store } = retrieveStore();
      // const storedLocale = store.get('locale') || fallbackLocale.value;    

      // get locale value from LocalStorage
      const storedLocale = (localStorage.getItem('netatmo-locale') || fallbackLocale.value) as string;


      // set the app locale from the session locale
      locale.value = storedLocale;
    }
  }


  return { ...useI18n(), reload };
}
