import { Application } from "@/feathers/declarations";
import { isSSR } from "@/helpers";
import { Request, Response } from 'express';
import { HttpMethods, IParams } from "@/models/app/core/params.interface";
import { ISSRContext } from "@/models/app/core/ssr-context.interface";
import { useRouteParams } from "../app/use-route-params";
import { retrieveUser } from "./retrieve-user";

/**
 * Retrieve the query parameter from express query
 * Only executable on Server, throw error in client
 * @return IParam composed of url, type, POSTED body and GET queries
 */
export function retrieveParams(ctx: { fapp: Application, req: Request, res: Response }): { params: IParams } {
  if (isSSR()) {
    const { req } = ctx
    const { id, method } = useRouteParams(req.method as HttpMethods)
    const { user } = retrieveUser();

    const params: IParams = {
      url: req.url,
      id: id,
      type: method || 'GET',
      post: req.body,
      get: req.query,
      user: user,
    };
    //console.log("[PARAMS]", params);
    return { params };
  } else {
    throw new Error("[APP] impossible to retrieve the query params on client")
  }
}