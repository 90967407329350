import { isSSR } from "@/helpers";
import { MDCSnackbar } from "@material/snackbar";
import _ from "lodash";
import { type } from "os";
import { AppContext, getCurrentInstance, onMounted } from "vue";
import renderComponent from "../../../materials/render-component";

export let afterClosed: any = () => {
  console.log("afterClosed");
};

export interface InputDialogOptions {
  title: string;
  text: string;
  valid?: string;
  noConfirm?: boolean;
}

const _propsCtx:InputDialogOptions = {
  title: "",
  text: "",
  valid: "YES",
  noConfirm: false
}

let destroyComp: any = null;
/**
 *
 * @param title title modal
 * @param text text modal
 * @param callback function after close
 */
export function useInputDialog() {
  const { appContext } = getCurrentInstance() || { appContext: undefined };

  async function dialog(  data: InputDialogOptions, callback?:any ) {
    if (!isSSR()) {
      if (appContext) {
        if (callback) {
          afterClosed = callback;
        }
        const alert = document.querySelector(".alert");
        destroyComp?.();
        const ctx = _.merge(_propsCtx, data);

        destroyComp = renderComponent({
          el: alert,
          component: (await import("./InputDialog.vue")).default,
          props: {
            title: ctx.title,
            text: ctx.text,
            valid: ctx.valid,
            noConfirm: ctx.noConfirm
          },
          appContext,
        });
      } else {
        console.log("NO CONTEXT", appContext);
      }
    }
  }

  return {
    open: async ( data: InputDialogOptions, callback?:any) => {
      return await dialog(data,callback);
    },
  };
}
