import { createGlobalState } from "@vueuse/core";
import _, { replace } from "lodash";
import { defineEmits, reactive, ref } from "vue";
import { useModes } from "./use-modes";
import { useLanguages } from "./use-languages";
import { useDatas } from "./use-datas";
import { FilesManagerNode } from "@/feathers/services/file-manager/file-manager.class";
import { useSearch } from "./use-search";

export interface ISelect {
	selections: ISelectable[];
	current?: ISelectable;
	racine?: ISelectable;
}

export interface ISelectable {
	[key: string]: any;
	// compare(other: ISelectable): boolean
}

export interface IReplace {
	field: string | undefined;
	pattern: string | undefined;
}

export const useMultiSelect = createGlobalState(() => {
	const { currentLanguage } = useLanguages();
	const { currentMode } = useModes();
	const { originalDatas } = useDatas();
	const { currentSearch, search } = useSearch();

	const currentSelection = reactive<ISelect>({
		selections: [],
		current: undefined,
		racine: undefined,
	});

	function addRacine(selectable: ISelectable) {
		currentSelection.racine = selectable;
	}

	function add(selectable: ISelectable, reset: boolean, ignoreCurrent = false, key: string) {
		if (reset) {
			currentSelection.selections = [selectable];
			if (!ignoreCurrent) {
				currentSelection.current = selectable;
			}
			if (!currentSelection.racine) {
				currentSelection.racine = selectable;
			}
		} else {
			const index = currentSelection.selections.findIndex((p) => p[key] === selectable[key]);
			if (index === -1) {
				currentSelection.selections.push(selectable);
			}
			if (!ignoreCurrent) {
				currentSelection.current = currentSelection.selections.slice(-1)[0] || undefined;
			}
		}
		console.log("[ADD SELECTION]", selectable, currentSelection);
	}

	function remove(selectable: ISelectable, ignoreCurrent = false, key: string) {
		_.remove(currentSelection.selections, (p) => p[key] === selectable[key]);

		if (!ignoreCurrent) {
			currentSelection.current = currentSelection.selections.slice(-1)[0] || undefined;
		}

		const splittedRelativePath = selectable.relativePath.split("/");
		const nbChildrenChecked = currentSelection.selections.filter(oneSelection =>
			oneSelection?.relativePath?.split("/")[0] === splittedRelativePath[0]).length;

		if (nbChildrenChecked === 0) {
			_.remove(currentSelection.selections, (p) => p[key] === splittedRelativePath[0]);
		}

		console.log("[REMOVE SELECTION]", selectable, currentSelection);
	}

	function get() {
		return currentSelection;
	}

	function find(current: FilesManagerNode, names: string[]) {
		const result: FilesManagerNode[] = [];
		const traverse = (node: FilesManagerNode, names: string[]) => {
			if (names.some(name => node.name.includes(name)) || names.some(name => node.path.includes(name))) {
				result.push(node);
			}
			for (const child of node.children || []) {
				traverse(child, names);
			}
		};
		traverse(current, names);
		return result;
	}

	function reset() {
		currentSelection.selections = [];
	}

	const included = (current: FilesManagerNode) => {
		return currentSelection.selections.some(selection => {
			if (current.relativePath.toLowerCase() === selection.relativePath.toLowerCase()) {
				return true;
			}
		});
	};

	const number = () => {
		// return currentSelection.selections.length * currentLanguage.languages.length * currentMode.modes.length;
		const allPossiblePaths: string[] = [];
		// Compute all possible paths
		currentSelection.selections.forEach(selection => {
			currentLanguage.languages.forEach(lang => {
				currentMode.modes.forEach(mode => {
					const possiblePath = `/${mode}/${lang}/${selection.relativePath}`;
					allPossiblePaths.push(possiblePath);
				});
			});
		});
		// Traverse the originalDatas.items tree
		const matchingNodes: ISelectable[] = [];

		const traverse = (nodes: ISelectable[]) => {
			if (!nodes) return;
			nodes.forEach(node => {
				if (allPossiblePaths.some(path => node.path.includes(path))) {
					matchingNodes.push(node);
				}
				if (node.children) {
					traverse(node.children);
				}
			});
		};
		traverse(originalDatas.items);
		return matchingNodes.length;

	};

	function selectRecursive(fileNode: FilesManagerNode) {
		if (!fileNode) {
			return;
		}

		if (!fileNode.children) {
			if (search.match(fileNode.path)) {
				add(fileNode, false, true, "path");
			}
			return;
		}

		if (fileNode.children) {
			for (const oneChild of fileNode.children) {
				selectRecursive(oneChild);
			}
		}

	}

	function removeRecursive(fileNode: FilesManagerNode) {
		if (!fileNode) {
			return;
		}
		const relPath = fileNode.relativePath;

		if (fileNode.children) {
			for (const oneChild of fileNode.children) {
				removeRecursive(oneChild);
			}
		}

		for (const oneSelected of currentSelection.selections) {
			const pathSelection = oneSelected.relativePath;
			if (pathSelection.includes(relPath)){
				remove(oneSelected, false, "path");
			}
		}
	}

	return {
		currentSelection,
		selectRecursive,
		removeRecursive,
		select: {
			addRacine,
			add,
			remove,
			reset,
			get,
			included,
			number,
			find
		},
	};
});
