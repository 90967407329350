import { createGlobalState } from "@vueuse/core";
import _ from "lodash";
import { Ref, reactive, ref, watch } from "vue";

export enum MODE {
	LIGHT = "light",
	DARK = "dark",
}

export interface IMode {
	modes: MODE[];
	current?: MODE;
}

export interface IModeFunction {
	add(modes: MODE[]): void;
	set(mode: MODE): void;
}

const displayMode: Ref<MODE> = ref(MODE.LIGHT);

export const useModes = createGlobalState((): {
	currentMode: IMode;
	selectMode: IModeFunction;
	displayMode: { current: Ref<MODE>; set: (mode: MODE) => void; };
} => {
	const currentMode = reactive<IMode>({
		modes: [],
		current: undefined,
	});

	function add(modes: MODE[]) {
		modes = modes.length ? modes : [MODE.LIGHT];
		const newItem = modes.filter((m) => !currentMode.modes.includes(m));
		currentMode.modes = _.reverse(_.uniq(_.reverse([...modes])));
		if (newItem[0]) {
			currentMode.current = newItem.pop();
		} else {
			currentMode.current = currentMode.modes[currentMode.modes.length - 1] || undefined;
		}
	}

	function set(mode: MODE): void {
		currentMode.modes = [mode];
		currentMode.current = mode;
	}

	function setDisplayMode(mode: MODE): void {
		displayMode.value = mode;
	}

	return {
		currentMode,
		selectMode: {
			add,
			set,
		},
		displayMode: {
			current: displayMode,
			set: setDisplayMode,
		},
	};
});
