import { createGlobalState } from "@vueuse/core";
import _ from "lodash";
import {defineEmits, reactive, ref} from "vue"

export interface ILanguage {
     languages: string[];
      current?: string;
}



export interface ILanguagesFunction {
  add(language: string, reset:boolean): void,
  remove(language: string): void,
  get(): ILanguage,
}



export const useLanguages = createGlobalState((): { currentLanguage: ILanguage, selectLanguage: ILanguagesFunction; } => {

  const currentLanguage = reactive<ILanguage>(
    {
      languages: [],
      current: undefined,
    }
  );

  function add(language: string, reset: boolean) {
    if (reset) {
      _reset();
      currentLanguage.current = language;
    }
  
    const index = currentLanguage.languages.findIndex(p => p === language);
    if (index === -1) {
      currentLanguage.languages = [...currentLanguage.languages, language];
    }
    
    
    currentLanguage.current = currentLanguage
      ? currentLanguage.languages[currentLanguage.languages.length - 1]
      : undefined;

    console.log('[ADD LANGUAGE]', language, currentLanguage);


  }  

  function remove(language: string) {
    _.remove(currentLanguage.languages, function (p) { return p === language; });

    currentLanguage.current = currentLanguage
      ? currentLanguage.languages[currentLanguage.languages.length - 1]
      : undefined;

    console.log('[REMOVE LANGUAGE]', language, currentLanguage);
  
  }

  function get() {
    return currentLanguage;
  }
  
  
  function _reset() {
    currentLanguage.languages = [];
  }

  return {
    currentLanguage,
    selectLanguage: {
      add,
      remove,
      get,
      
    }
  };


});





