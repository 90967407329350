import { isSSR } from "@/helpers";
import io = require('socket.io-client');
import { inject, provide } from "vue";
import { retrieveStore } from "../server/retrieve-store";

/**
  * Create an socket.io client, connect it to the server and automatically authenticate from the stored auth token 'jwt' token 
  * @params onConnect call backs when needed to start emiting right after the connection
  * @params onAuth call backs when needed to start emiting right upon a successfull authentication
  * @returns an instance of the socket.io client {@link SocketIOClient.Socket}, call emit, listen on event, etc..
 */
export const useSocketIO = () => {
  const { store } = retrieveStore();

  const storedSocket: SocketIOClient.Socket | undefined = inject('socketio');
  const socket = storedSocket || io({ // init socket.io
    transports: ['websocket'],
    rejectUnauthorized: false
  });

  if (!storedSocket)
    provide('socketio', socket);

  if (!isSSR() && socket.disconnected) {
    // client-side
    socket.on('connect', async () => {

      /** Authenticating */
      if (!storedSocket) {
        const accessToken = store.get('accessToken');
        if (accessToken) {
          socket.emit('create', 'authentication', {
            strategy: 'jwt',
            accessToken: store.get('accessToken')
          }, async function (error: any, authResult: any) {
            if (authResult) {
              console.log("[SOCKET AUTH] Success", storedSocket, accessToken);
            }

            if (error) {
              console.log("\x1b[31m%s\x1b[0m", "[SOCKET ERROR] AUTH", error);
            }
          });
        }
      }
    });

    socket.on("connect_error", (err: any) => {
      console.log("\x1b[31m%s\x1b[0m", `[SOCKET ERROR] connect_error due to ${err.message}`);
    });

    socket.on("disconnect", () => {
      console.log("\x1b[31m%s\x1b[0m", '[SOCKET] DISCONNECT'); // undefined
    });

  }

  function onAuth(cb: (authResult: any) => void | Promise<void>) {
    socket.on('authentication on-auth', async (authResult: any) => {
      await cb(authResult);
    });
  }

  function onConnect(cb: () => void | Promise<void>) {
    socket.on('connect', async () => {
      await cb();
    });
  }

  const emit = async (event: string, ...args: any[]) => {
    return new Promise<any>((resolve, reject) => {
      socket.emit(event, ...args, (err: any, data: any[]) => {
        if (err) {
          reject(err);
        } else {
          resolve(data);
        } 
      });
    }); 
  };

  return {
    socket,
    emit,
    onAuth,
    onConnect
  };
};