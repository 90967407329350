import { MODE } from "@/vue/composables/app/use-modes";
import { ALL } from "dns";

export default {
    CHANGE_TOOL_LANGUAGE: "Change tool language",
    CANCEL: "Cancel",
    HELLO: "Hello %{name}",
    HOME: "Home",
    EMAIL: "Email",
    LANGUAGES: "Screens languages",
    MODES: "Modes",
    SUBMIT: "Send",
    TAGS: "Tags",
    LASTNAME: "Lastname",
    FIRSTNAME: "Firstname",
    PASSWORD: "Password",
    DELETE: "Delete",
    ARE_YOU_SUR_DELETE: "Are you sure you want to delete ?",
    NOT_LOGGED_IN: "Not logged in ?",
    LOGIN: "Log in",
    REGISTER: "Register",
    FR: "Français",
    EN: "English",
    OR: "Or",
    WELCOME: "Welcome !",
    QUIT_ADMIN: "QUIT ADMIN",
    WELCOME_P1:
        "The <strong>SMART SCREEN</strong> tool allows you to search and download <strong>Home + Control</strong> app screens for your various communication media.",
    WELCOME_P2:
        'It\'s easy to connect, click on "Connect with my Legrand/Bticino account" and just use your username and password <strong>Legrand or Bticino.</strong>',
    LOG_WITH_LG: "Connect with my Legrand/Bticino account",
    UNLOCK_ADMIN_MODE: "Unlock admin mode",
    ADMIN_MODE_PASSWORD: "Admin password",
    Login: "Log in",
    ADMIN_MODE_ACTIVATED: "Admin mode activated",
    ADMIN_MODE_ERROR: "Admin mode error",
    ADMIN_MODE_DEACTIVATED: "Admin mode deactivated",
    ERRORS: {
        BAD_REQUEST: "Sent elements are invalids",
        NOT_AUTHENTICATED: "You are not authenticated",
        FORBIDDEN: "Forbidden",
        NOT_FOUND: "Element not found",
        METHOD_NOT_ALLOWED: "Method not allowed",
        CONFLICT: "This value isn't unique : ",
        GENERAL_ERROR: "Unknown error",
        MISSING: "This field is required",
        INVALID_LOGIN: "Invalid login",
        PASSWORD_LENGTH: "The password has to contains at least 8 characters",
        EMAIL: "Email address invalid",
        LONG: "Too long",
        SHORT: "Too short",
    },
    ROUTES: {
        BACK: "Back",
        ERRORS: {
            401: "You are not authenticated.",
            403: "You do not have permission to access this page.",
            404: "The page you are looking for does not exist.",
        },
        HOME: "Home",
    },
    LOGOUT: "Logout",
    CLOSE: "Close",
    PERIODE: "Period",
    TOTAL_CONNECTIONS: "Total connections",
    DELETE_FILE: "Delete file",
    FILE_DELETED: "File deleted",
    DELETE_FILE_CONFIRM: "Are you sure you want to delete this file ?",
    DETAILS: {
        TYPE: "Type",
        FILE: "File",
        WEIGHT: "Size",
        RESOLUTION: "Resolution",
        CREATION_DATE: "Created at",
        MODIFICATION_DATE: "Edited at",
        DOWNLOAD: "Download",
        SELECT: "Select",
    },
    SEARCH: "Search...",
    SCREENS: "screens",
    CHANGE_LANGUAGE: "Change language",
    NAME_ASC: "Name (A-Z)",
    NAME_DESC: "Name (Z-A)",
    CREATION_ASC: "Creation",
    CREATION_DESC: "Creation (revert)",
    EDITION_ASC: "Edition",
    EDITION_DESC: "Edition (revert)",
    CTRL_CLICK_EXPL:
        "To download the screens in one language, select the desired language. To download screens in multiple languages simultaneously, select the desired languages by holding down the CTRL key.",
    SELECT_ALL: "Select all",
    DESELECT_ALL: "Deselect all",
    KPI: "KPI",
    TOTAL: "Total",
    NB_OF_VIEWED_SCREENS: "Number of viewed screens",
    NB_OF_INPRINT_SCREENS: "Number of imprinted screens",
    NB_OF_DOWNLOADED_SCREENS: "Number of downloaded screens",
    LANGUAGES_OF_DOWNLOADED_SCREENS: "Languages of downloaded screens",
    PARAMETERS: "Parameters",
    CONNECTIONS: "Connections",
    FROM: "From",
    TO: "To",
    ALL: "All",
    LIGHT: "Light",
    DARK: "Dark",
    MODE: "Mode",
    ON_DATERANGE: "On the period",
    INDEPENDANT_OF_DATERANGE: "Independently of the period",
    MOST_POPULAR_SCREENS: "Most popular screens",
    VIEWED: "Viewed",
    DOWNLOADED: "Downloaded",
    CODE_ISO: "ISO code",
    LANGUAGE: "Language",
    DOWNLOADS: "Downloads",
    SDTABLE: {
        NO_DATA: "No data",
    },
    SCREEN: "Screen",
    NB_VIEWED: "Number of views",
    NB_DOWNLOADED: "Number of downloads",
};
