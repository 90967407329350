import { Store } from "./index";

import _ from 'lodash';

export interface AppState extends Object {
    [key:string]:any;
}

export class AppStore extends Store<AppState> {
    protected hydrate(state: Record<string, unknown>): any {
        return (state as any).app || this.data();
    }

    protected data(): AppState {
        return {
            fetching: false
        };
    }

    /**
     * Set a data into the shared store between client and server
     * Use this mecanique to retrieve Server Side computed data in client
     * @param path component path, default is filename, you can overide this param by overiding this path property from SSRV, or by binding it in template
     * @param data data to store
     */
    public set(path: string, data: any){
        _.set(this.state, `${path}`, data)
    }

    /**
     * Get a data into the shared store between client and server
     * Use this mecanique to retrieve Server Side computed data in client
     * @param path component path, default is filename, you can overide this param by overiding this path property from SSRV, or by binding it in template
     * @returns 
     */
    public get(path: string) {
        return _.get(this.state, `${path}`);
    }
}
export const store: AppStore = new AppStore()