import _, { replace } from "lodash";
import { Ref, UnwrapNestedRefs, defineEmits, reactive, ref } from "vue";

export function useSort(): {
	sorting: Ref<string>;
	changeSort(sort: string): void;
} {
	const sorting: Ref<string> = ref("name_asc");

	const changeSort = (sort: string): void => {
		sorting.value = sort;
	};

	return {
		sorting,
		changeSort,
	};
}
