import { HttpMethods } from '@/models/app/core/params.interface';
import { useRoute } from 'vue-router';

export const enum VIEWMODE {
  NONE = 'none',
  CREATE = 'create',
  EDIT = 'edit',
  VIEW = 'view'
}

/**
 * Retrieve the route params, named with :id and :method from dynamique crud routes
 * @return the :id and the :method in the dynamique url as id and method
 */
export function useRouteParams(reqMethod?: HttpMethods): { id?: string, mode: VIEWMODE,  method?: HttpMethods } {

  const methodMap: { [key in HttpMethods]: string[] } = {
    'GET': ['GET', 'get'],
    'POST': ['POST', 'post'],
    'PATCH': ['PATCH', 'patch'],
    'DELETE': ['DELETE', 'delete'] 
  }

  const viewMap: { [key in VIEWMODE]: string[] } = {
    'none': [],
    'view': ['view'],
    'create': ['create'],
    'edit': ['edit'] 
  }

  function parseView(matchedPath: string): VIEWMODE | undefined {
    let final_mode = undefined;
    for(const key of (Object.keys(viewMap)) ){
      if(viewMap[key as VIEWMODE].includes(matchedPath)){
        final_mode = key as VIEWMODE;
      }
    }
    return final_mode;
  }

  function parseMethod(method: string): HttpMethods | undefined {
    let final_method = undefined;
    for(const key of (Object.keys(methodMap)) ){
      if(methodMap[key as HttpMethods].includes(method)){
        final_method = key as HttpMethods;
      }
    }
    return final_method;
  }

  const route = useRoute();
  let id = undefined;
  let method = reqMethod;
  let mode = VIEWMODE.NONE;
  if (route && route.params && route.params.id) {
    id = route.params.id as string;
  }
 
  if (route && route.params && route.params.pathMatch) {
    const pathMatch = route.params.pathMatch as string;
    mode = parseView(pathMatch) || VIEWMODE.NONE;
    method = parseMethod(pathMatch) || reqMethod;
  }

  // if (route && route.params && route.params.method) {
  //   method = parseMethod(route.params.method as string) || reqMethod || 'GET';
  // }

  return { id, method, mode };

}

