import { IImageBase64 } from "@/feathers/services/file-manager/file-manager.class";
import { MODE, useModes } from "./use-modes";
import { useLanguages } from "./use-languages";
import { useMultiSelect } from "./use-multi-select";
import { useSocketIO } from "./use-socket";


export function useReverseModeFileExists() {
	const { emit } = useSocketIO();

	const { currentMode } = useModes();

	async function verify(element: IImageBase64[]) {
		let toFindMode: MODE = MODE.DARK;

		if (currentMode && currentMode.current && currentMode.current === MODE.DARK) {
			toFindMode = MODE.LIGHT;
		}

		const pathList = [];

		for (const oneElem of element) {
			if (toFindMode === MODE.DARK) {
				pathList.push(oneElem.path.replace("/light/", "/dark/"));
			} else {
				pathList.push(oneElem.path.replace("/dark/", "/light/"));
			}
		}

		try {
			const data = await emit("find", "api/download", {
				paths: pathList,
				peek: true,
			});
			if (data) {
				return {
					data: data.map((el: any) => {
						return {
							exists: el.exists,
							path: toFindMode === MODE.DARK ? el.path.replace("/dark/", "/light/") : el.path.replace("/light/", "/dark/"),
						};
					}),
					mode: toFindMode,
				};
			}
		} catch (err) {
			console.error("[DOWNLOAD]", err);
			throw err;
		}
	}

	return { verify };
}
