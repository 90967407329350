import _ from "lodash";
import { StatsType } from "../../../models/app/business/stats.model";
import { useSocketIO } from "./use-socket";
import { filter } from "lodash";

export function useStats() {
    const { emit } = useSocketIO();

    const connection = {
        async number(start?: Date, end?: Date): Promise<number> {
            let query = {};
            if (start && end) {
                query = { ...query, createdAt: { $gte: start, $lte: end } };
            }
            const result = await emit('find', 'api/stats', { ...query, slug: StatsType.CONNECTION, $limit: 0 });
            return result.total;
        },
    };

    const add = {
        async viewed(paths: string[]) {
            paths = assert.unique_in_session(paths, StatsType.DISPLAY);
            console.log('UNIQUES', paths);
            if (paths.length) {
                const result = await emit('create', 'api/stats/add', { type: StatsType.DISPLAY, paths });
                console.log(result, 'STATSADD');    
            }            
        },
        async inprinted(paths: string[]) {
            paths = assert.unique_in_session(paths, StatsType.INPRINT);
            if (paths.length) {
                const result = await emit('create', 'api/stats/add', { type: StatsType.INPRINT, paths });
                console.log(result, 'STATSADD');    
            }            
        }
    }

    const assert = {
        unique_in_session : (paths: string[], type: StatsType) => {
            const data: any = JSON.parse(sessionStorage.getItem('smart-screen-usage-data-'+type) || '{}');
            const uniqs = filter(paths, path => {
                const splitted = path.split('/');
                const mode = splitted[splitted.length - 4];
                const lang = splitted[splitted.length - 3];
                const relativePath = _.join(splitted.slice(splitted.length - 2), '/');
                if (!data[`${mode}-${lang}-${relativePath}`]) {
                    return data[`${mode}-${lang}-${relativePath}`] = true;
                }
                return false;
            });
        
            sessionStorage.setItem('smart-screen-usage-data-'+type, JSON.stringify(data));
            return uniqs;
        }
    }


    const screens = {
        async downloaded(mode: 'light' | 'dark' | 'all', options: { page: number, limit: number, sort: { field: string, direction: number; }; }, start?: Date, end?: Date) {
            let query = {};
            if (start && end) {
                query = { ...query, createdAt: { $gte: start, $lte: end } };
            }

            if (mode && mode !== 'all') {
                query = { ...query, mode };
            }

            if (options) {
                query = {
                    ...query,
                    $limit: options.limit,
                    $skip: (options.page - 1) * options.limit || 0,
                    $sort: { [options.sort.field.toString()]: options.sort.direction }
                };
            }

            console.log('query', query);

            const result = await emit('find', 'api/stats', { ...query, slug: StatsType.SCREENS });
            return result;
        },
        async viewed(mode: 'light' | 'dark' | 'all', options: { page: number, limit: number, sort: { field: string, direction: number; }; }, start?: Date, end?: Date) {
            let query = {};
            if (start && end) {
                query = { ...query, createdAt: { $gte: start, $lte: end } };
            }

            if (mode && mode !== 'all') {
                query = { ...query, mode };
            }

            if (options) {
                query = {
                    ...query,
                    $limit: options.limit,
                    $skip: (options.page - 1) * options.limit || 0,
                    $sort: { [options.sort.field.toString()]: options.sort.direction }
                };
            }

            const result = await emit('find', 'api/stats', { ...query, slug: StatsType.SCREENS });
            return result;
        }
    };

    const totals = {
        async inprinted(options?: { year?: number, month?: number, day?: number; } | { start?: Date, end?: Date; }) {
            let query = {};
            if (options) {
                if ('year' in options) {
                    query = { ...query, year: options.year };
                }
                if ('month' in options) {
                    query = { ...query, month: options.month };
                }
                if ('day' in options) {
                    query = { ...query, day: options.day };
                }
                if ('start' in options && 'end' in options) {
                    query = { ...query, createdAt: { $gte: options.start, $lte: options.end } };
                }
            }
            const result = await emit('find', 'api/stats', { ...query, slug: StatsType.INPRINT });
            return result;
        },
        async displayed(options?: { year?: number, month?: number, day?: number; } | { start?: Date, end?: Date; }) {
            let query = {};
            if (options) {
                if ('year' in options) {
                    query = { ...query, year: options.year };
                }
                if ('month' in options) {
                    query = { ...query, month: options.month };
                }
                if ('day' in options) {
                    query = { ...query, day: options.day };
                }
                if ('start' in options && 'end' in options) {
                    query = { ...query, createdAt: { $gte: options.start, $lte: options.end } };
                }
            }
            const result = await emit('find', 'api/stats', { ...query, slug: StatsType.DISPLAY });
            return result;
        },
        async download(options?: { year?: number, month?: number, day?: number; } | { start?: Date, end?: Date; }) {
            let query = {};
            if (options) {
                if ('year' in options) {
                    query = { ...query, year: options.year };
                }
                if ('month' in options) {
                    query = { ...query, month: options.month };
                }
                if ('day' in options) {
                    query = { ...query, day: options.day };
                }
                if ('start' in options && 'end' in options) {
                    query = { ...query, createdAt: { $gte: options.start, $lte: options.end } };
                }
            }
            const result = await emit('find', 'api/stats', { ...query, slug: StatsType.DOWNLOAD });
            return result;
        }
    };

    const langs = {
        async sorted(start: Date, end: Date, options: { page: number, limit: number, sort: { field: string, direction: number; }; }) {
            let query = {};
            if (start && end) {
                query = { ...query, createdAt: { $gte: start, $lte: end } };
            }

            if (options) {
                query = {
                    ...query,
                    $limit: options.limit,
                    $skip: (options.page - 1) * options.limit || 0,
                    $sort: { [options.sort.field.toString()]: options.sort.direction }
                };
            }

            const result = await emit('find', 'api/stats', { ...query, slug: StatsType.LANGUAGE });
            return result;
        }
    };

    return {
        connection,
        screens,
        totals,
        langs,
        add,
    };
}