import { Conflict, FeathersError } from "@feathersjs/errors";
import _ from "lodash";
import { onMounted, UnwrapNestedRefs } from "vue";
import { useAlert } from "../../services/alert/use-alert";
import { useLocale } from "../../use-locale";
import { HandledErrorName, parseFeathersErrors } from "./parse-feathers-errors";

/**
 * handle the recieved feathers error in data._ferror and display it and it's value in an alert
 * @param data the state vue reactive data
 * @param _options a map to bind specific translatable message to error codes
 */
export function useFeathersErrors(data: UnwrapNestedRefs<any>, _options?: Partial<{
  [key in HandledErrorName]: string
}>) {
  if (data && data._ferrors) {
    const alert = useAlert();

    const { t } = useLocale();
    const { ferror } = parseFeathersErrors(data, _options);

    onMounted(() => {
      if(!_.isNil(ferror)){
        console.group('[Feathers Error]', ferror.name);
        console.log("\x1b[31m%s\x1b[0m", ferror.message);
        console.log(ferror);
        console.groupEnd();
        alert.error(`${t(ferror.message)} ${ferror.data? ferror.data : ''}`);
      }
    })
    return { ferror }
  }

  return { ferror: undefined }
}