import { createApplication } from "./app";
import { loadFonts } from "./plugins/webfontloader";

const { app, router } = createApplication();

(async (r, a) => {
	loadFonts();
	await r.isReady();

	a.mount("#app", true);
})(router, app);
