import { createApp, createSSRApp } from "vue";
import { Router } from "vue-router";
import { createApplicationRouter } from "./routing/router";
import { isSSR } from "@/helpers";
import { store } from "./store/app";
import App from "./vue/App/App.vue";
import { i18n } from "./vue-i18n";

// Shared
import { sharedPlugin } from "./vue/shared/sharedPlugins";

// vuetify
import { createVuetify } from "vuetify";
import VuetifyTheme from "./vue/theme/vuetify-theme";

// Loader
import { LoadingPlugin as loadingPlugin } from "vue-loading-overlay";
import { loadingConfiguration } from "./vue/theme/vue-loader-config";
// import '../node_modules/vue-loading-overlay/dist/css/index.css';

// DONT work in docker, but app seems to work without it
// import "vuetify/lib/styles/main.css";
// import "@mdi/font/css/materialdesignicons.css";

// POLYFILL
import { document } from './app/polyfill';

export function createApplication() {
    // clear store on each SSR call
    store.clear();

    const app = isSSR() ? createSSRApp(App) : createApp(App);

    // Create VueI18n instance with options
    app.use(i18n);

    // Routing from routes.ts
    const router: Router = createApplicationRouter();
    app.use(router);

    // our shared plugins
    app.use(sharedPlugin);

    // Instantiate vuetify
    const vuetify = createVuetify({
        ssr: isSSR(),
        theme: VuetifyTheme,
    });
    app.use(vuetify);

    // Loading
    app.use(
        loadingPlugin,
        loadingConfiguration.props,
        loadingConfiguration.slots
    );

    // POLYFILL
    app.config.globalProperties.$document = document;

    return { app, router };
}
