import { FilesManagerNode } from "@/feathers/services/file-manager/file-manager.class";
import _, { } from "lodash";
import { reactive } from "vue";
import { ILanguage } from "./use-languages";
import { ISelectable, useMultiSelect } from "./use-multi-select";
import { IMode, MODE, useModes } from "./use-modes";
import { createGlobalState } from "@vueuse/core";
import { useSearch } from "./use-search";



export const usePreviewDisplay = createGlobalState(() => {
	let oldDisplayed: ISelectable = { item: null };
	let oldLang: ILanguage;

	const currentDisplayed = reactive<ISelectable>({
		item: null,
	});
	let currentLanguage: ILanguage;

	const { currentSelection, select } = useMultiSelect();
	const { currentMode } = useModes();
	const { search } = useSearch();

	function set(selectable: ISelectable, updateOld = true) {
		if (currentDisplayed.item && currentDisplayed.item.children && currentDisplayed.item.children.length > 0) {
			const lightIndex = currentDisplayed.item.path.split("/").indexOf("light");
			const darkIndex = currentDisplayed.item.path.split("/").indexOf("dark");

			const relativeLength =
				lightIndex > -1
					? currentDisplayed.item.path.split("/").length - 1 - lightIndex
					: currentDisplayed.item.path.split("/").length - 1 - darkIndex;

			if (relativeLength > 1) {
				oldDisplayed = { ...currentDisplayed.item };
			}
		}

		let newSelectable = selectable;

		if (selectable && selectable.path) {
			const lightIndex = selectable.path.split("/").indexOf("light");
			const darkIndex = selectable.path.split("/").indexOf("dark");

			const relativeLength =
				lightIndex > -1 ? selectable.path.split("/").length - 1 - lightIndex : selectable.path.split("/").length - 1 - darkIndex;


			if (relativeLength < 2) {
				newSelectable = oldDisplayed;
			}
		}

		currentDisplayed.item = newSelectable;
	}

	function setLanguage(lang: ILanguage) {
		if (oldLang && currentDisplayed.item) {
			if (currentDisplayed.item.path)
				currentDisplayed.item.path = currentDisplayed.item.path.replace("/" + oldLang.current + "/", "/" + lang.current + "/");
			if (currentDisplayed.item.children) {
				const newLangChildren: any = [];
				currentDisplayed.item.children.forEach((item: any) => {
					newLangChildren.push({ ...item, path: item.path.replace("/" + oldLang.current + "/", "/" + lang.current + "/") });
				});
				currentDisplayed.item.children = newLangChildren;
			}
		}
		oldLang = { ...lang };
		currentLanguage = lang;
	}

	function setMode(mode: MODE) {

		let from = "/dark/";
		let to = "/light/";
		if (mode === MODE.DARK) {
			to = "/dark/";
			from = "/light/";
		}
		if (currentDisplayed.item?.path) {
			currentDisplayed.item.path = currentDisplayed.item.path.replace(from, to);
			if (currentDisplayed.item.children) {
				const newModeChildren: any = [];
				currentDisplayed.item.children.forEach((item: any) => {
					newModeChildren.push({ ...item, path: item.path.replace(from, to) });
				});
				currentDisplayed.item.children = newModeChildren;
			}
		}
	}

	function get() {
		return currentDisplayed;
	}

	function parent() {
		// let toFindPath = "";
		// if (currentMode.current === MODE.LIGHT) {
		// 	toFindPath = oldDisplayed.path.replace("/dark/", "/light/");
		// } else {
		// 	toFindPath = oldDisplayed.path.replace("/light/", "/dark/");
		// }	
		// const allDatas = currentSelection.racine ? currentSelection.racine.children : [];
		// const toFindSelectable = allDatas.find((item: any) => item.path === toFindPath);

		const parent = select.find(currentSelection.racine as FilesManagerNode, [currentDisplayed.item.path.split("/")[currentDisplayed.item.path.split("/").length - 2]]).filter((item: any) => !!item.children).pop();

		if (parent) {
			set(parent);
		}
	}

	function isEmptyFolder(index: number) {
		const siblings = currentSelection.racine ? currentSelection.racine.children : [];
		if (index < 0 || index >= siblings.length) return true;
		const item: FilesManagerNode = siblings[index];
		return item && item.children && item.children.filter(c => search.match(c.relativePath)).length === 0;
	}

	function hasPrevious() {
		if (currentDisplayed.item.children) {
			const siblings = currentSelection.racine ? currentSelection.racine.children : [];
			const index = siblings.findIndex((item: any) => item.path === currentDisplayed.item.path);
			for (let i = index - 1; i >= 0; i--) {
				if (!isEmptyFolder(i)) {
					return true;
				}
			}
		} else {
			const siblings = select.find(currentSelection.racine as FilesManagerNode, [currentDisplayed.item.path.split("/")[currentDisplayed.item.path.split("/").length - 2]]).filter((item: any) => !item.children);
			const index = siblings.findIndex((item: any) => item.path === currentDisplayed.item.path);
			return index > 0;
		}
	}

	function hasNext() {
		if (currentDisplayed.item.children) {
			const siblings = currentSelection.racine ? currentSelection.racine.children : [];
			const index = siblings.findIndex((item: any) => item.path === currentDisplayed.item.path);
			for (let i = index + 1; i < siblings.length; i++) {
				if (!isEmptyFolder(i)) {
					return true;
				}
			}

		} else {
			const siblings = select.find(currentSelection.racine as FilesManagerNode, [currentDisplayed.item.path.split("/")[currentDisplayed.item.path.split("/").length - 2]]).filter((item: any) => !item.children);
			const index = siblings.findIndex((item: any) => item.path === currentDisplayed.item.path);
			return index < siblings.length - 1;
		}
	}

	function previous() {
		if (currentDisplayed.item.children) {
			const siblings = currentSelection.racine ? currentSelection.racine.children : [];
			const index = siblings.findIndex((item: any) => item.path === currentDisplayed.item.path);
			for (let i = index - 1; i >= 0; i--) {
				if (!isEmptyFolder(i)) {
					set(siblings[i]);
					return;
				}
			}

			// if (index > 0) {
			// 	set(siblings[index - 1]);
			// }
		} else {
			const siblings = select.find(currentSelection.racine as FilesManagerNode, [currentDisplayed.item.path.split("/")[currentDisplayed.item.path.split("/").length - 2]]).filter((item: any) => !item.children);
			const index = siblings.findIndex((item: any) => item.path === currentDisplayed.item.path);
			if (index > 0) {
				set(siblings[index - 1]);
			}
		}
	}

	function next() {
		if (currentDisplayed.item.children) {
			const siblings = currentSelection.racine ? currentSelection.racine.children : null;
			const index = siblings.findIndex((item: any) => item.path === currentDisplayed.item.path);
			for (let i = index + 1; i < siblings.length; i++) {
				if (!isEmptyFolder(i)) {
					set(siblings[i]);
					return;
				}
			}
		} else {
			const siblings = select.find(currentSelection.racine as FilesManagerNode, [currentDisplayed.item.path.split("/")[currentDisplayed.item.path.split("/").length - 2]]).filter((item: any) => !item.children);
			const index = siblings.findIndex((item: any) => item.path === currentDisplayed.item.path);
			if (index < siblings.length - 1) {
				set(siblings[index + 1]);
			}
		}
	}

	function reset() {
		currentDisplayed.item = null;
	}

	return {
		currentDisplayed,
		displayFunctions: {
			set,
			reset,
			get,
			setLanguage,
			setMode,
			parent,
			hasPrevious,
			hasNext,
			previous,
			next,
		},
	};
});