import { Conflict, FeathersError } from "@feathersjs/errors";
import _ from "lodash";
import { UnwrapNestedRefs } from "vue";


export type HandledErrorName = 'BadRequest' | 'NotAuthenticated' | 'Forbidden' | 'NotFound' | 'MethodNotAllowed' | 'Conflict' | 'GeneralError' | 'Invalid login';

const defaultOptions: {
  [key in HandledErrorName]: string
} = {
  'BadRequest': "ERRORS.BAD_REQUEST",
  'NotAuthenticated': "ERRORS.NOT_AUTHENTICATED",
  'Forbidden': "ERRORS.FORBIDDEN",
  'NotFound': "ERRORS.NOT_FOUND",
  'MethodNotAllowed': "ERRORS.METHOD_NOT_ALLOWED",
  'Conflict': "ERRORS.CONFLICT",
  'GeneralError': "ERRORS.GENERAL_ERROR",
  'Invalid login': "ERRORS.INVALID_LOGIN"
}
/**
 * Interprete the various form a feather error can take
 * need to be completed over time
 * insert custom/translatable message in it
 * @param data the state vue reactive data
 * @param _options a map to bind specific translatable message to error codes
 */
export function parseFeathersErrors(data: UnwrapNestedRefs<{ _ferrors?: FeathersError }>, _options?: Partial<{
  [key in HandledErrorName]: string
}>) {
  if (data && data._ferrors && data._ferrors) {
    const options = _.assign({}, defaultOptions, _options);

    let ferror: FeathersError = data._ferrors;

    // treatment for sequelize model error as they are embedded inside a feather error
    if (ferror.errors && ferror.errors[0]) {
      const embedded_error = ferror.errors[0]; // only take the first one
      switch (embedded_error.validatorKey) {
        case 'not_unique':
          ferror = new Conflict(embedded_error.message, embedded_error.value);
          break;

        default:
          console.warn("[useFeathersErrors] Unhandled error type", embedded_error.validatorKey, embedded_error);
          break;
      }
    }

    ferror = setDisplayMessage(ferror, options);

    return { ferror };
  }
  return {};
}

function setDisplayMessage(ferror: FeathersError, options: { [key in HandledErrorName]: string }) {

  // Add special use case
  const specialUseCase = ['Invalid login'];
  console.log(ferror.message);
  if (specialUseCase.includes(ferror.message)) {
    switch (ferror.message) {
      case 'Invalid login':
        ferror.message = options['Invalid login'];
      break;
    }
  } else {
    // map error code with view message
    const displayMessage = options[ferror.name as HandledErrorName];
    if (displayMessage) {
      ferror.message = ferror.message || displayMessage;
    } else {
      console.warn('[useFeathersErrors] unHandled error name', ferror.name, ferror);
    }
  }

  return ferror
}