import { Plugin } from "vue";
import PageMeta from "../App/metas/PageMeta.vue";
import PageMetaTeleport from "../App/metas/PageMetaTeleport.vue";
import vCsrf from './components/v-csrf.vue';
import vRouterLink from './components/router-link.vue';
import vConfirmButton from './components/v-confirm-button.vue';
import vDeleteButton from './components/v-delete-button.vue';
// import Vue3EasyDataTable from 'vue3-easy-data-table';

// import 'vue3-easy-data-table/dist/style.css';


declare module "vue" { }

export const sharedPlugin: Plugin = {
    install: (app, options) => {
        // Metas
        app.component("PageMeta", PageMeta);
        app.component("PageMetaTeleport", PageMetaTeleport);

        // vuetify table were not already implemented
        // app.component('EasyDataTable', Vue3EasyDataTable);

        // overriding the defqult router-link cause we need a hard refresh each time
        app.component('router-link', vRouterLink);

        app.component('v-csrf', vCsrf);
        app.component('v-confirm-btn', vConfirmButton);
        app.component('v-delete-btn', vDeleteButton);
    },
};
