import { IImageBase64 } from "@/feathers/services/file-manager/file-manager.class";
import { fstat } from "fs";
import { reactive } from "vue";
import { ILanguage, useLanguages } from "./use-languages";
import { IMode, useModes } from "./use-modes";
import { ISelect, ISelectable, useMultiSelect } from "./use-multi-select";
import { useSocketIO } from "./use-socket";
import { saveAs } from "file-saver";
import _ from "lodash";

export function useDownload() {
	const { emit } = useSocketIO();

	const download = async (overrideSelection?: ISelect) => {
		const { currentMode } = useModes();
		const { currentLanguage } = useLanguages();
		const { currentSelection } = useMultiSelect();

		const selection = overrideSelection ? overrideSelection : currentSelection;
		console.log("[DOWNLOAD]", currentMode, selection);

		const selectioned = [];
		for (const mode of currentMode.modes) {
			for (const select of selection.selections) {
				for (const lang of currentLanguage.languages) {
					const path = `sftp-seeds/${mode}/${lang}/${select.relativePath}`;
					selectioned.push(path);
				}
				// if(select.children){
				// 	for (const c of select.children) {
				// 		for (const lang of currentLanguage.languages) {
				// 			const path = `${mode}/${lang}/${c.relativePath}`;
				// 			selectioned.push(path);
				// 		}
				// 	}
				// }
			}
		}
		console.log("[DOWNLOAD ZIP]", _.uniq(selectioned));
		try {
			const data = await emit("find", "api/download", {
				paths: _.uniq(selectioned),
			}
			);
			if (data) {
				console.log("[DATA]", data);
				console.log("[byteLength]", data.buffer.byteLength);
				const blob = new Blob([data.buffer], {
					type: data.type,
				});
				const fileName = data.name;
				saveAs(blob, fileName);
			}
		} catch (err) {
			console.error("[DOWNLOAD]", err);
		}
	};

	return { download };
}
