import _ from "lodash";
import { reactive } from "vue";
import { retrieveStore } from "../server/retrieve-store";

/**
 * @return the data stored on the server or in the client or from server to client, data is a reactive object
 */
export function useStored<T extends Record<string,any>> (path: string) {

  const { store } = retrieveStore();
  // const stored = store.get(path);

  const stored = reactive<T>(store.get(path) || {});

  /** Return reactive stored object */
  return { data: stored };
}