import { Request } from "express";
import _ from "lodash";

/**
 * Permet d'utiliser le SessionStore. Celui-ci nous permet de stocker des données d'une page à l'autre, sur le server, pour un même utilisateur, pendant toute la durée de sa session. 
 * @param req la requête Express transmise depuis le SSRContext.  
 * @returns function {@link store}, function {@link get}, function {@link clean}  
 */
export function useSessionStorage(req: Request) {

    return {
        store: (path:string, data:any): void => {
            _.set(req.session, path, data);
        },
        get: (path:string): any | undefined => {
            return _.get(req.session, path)
        },
        clean: (path:string): void => {
            _.unset(req.session, path);
        }
    }
}