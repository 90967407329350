
export enum StatsType {
    CONNECTION = 'connection',
    SCREENS = "screens",
	LANGUAGE = "language",
	DOWNLOAD = "download",
    DISPLAY = "display",
    INPRINT = 'inprint'
}

export interface IStats {
    slug: StatsType;
    payload: any;
    creadtedAt: Date;
    updatedAt: Date;
}