import { toFormValidator } from "@vee-validate/zod";
import _ from "lodash";
import { FormContext, useField, useForm } from "vee-validate";
import { z } from "zod";

/**
 * init form
 * @param validationSchema Schema form width fields and validators
 * @param initialValues Initial value / field
 * @returns formContext, values, errors, handlers, metas
 */
export function useFormView(validationSchema: z.ZodObject<any>, initialValues?: any) {
    const formContext = useForm({
        validationSchema: toFormValidator(validationSchema as any),
        initialValues: initialValues || {},
        initialTouched: map(initialValues),
        validateOnMount: !_.isEmpty(initialValues),
    });

    const keys = validationSchema.keyof().Values;

    const values: { [key: string]: any } = {};
    const errors: { [key: string]: any } = {};
    const handlers: { [key: string]: any } = {};
    const metas: { [key: string]: any } = {};

    for (const key of Object.keys(keys)) {
        const {
            value: v,
            errorMessage,
            handleChange,
            handleBlur,
            meta,
        } = useField(key, "", {
            validateOnValueUpdate: true,
        });
        values[key] = v;
        errors[key] = errorMessage;
        handlers[key] = { blur: handleBlur, change: handleChange };
        metas[key] = meta;
    }

    return { formContext, values, errors, handlers, metas };
}

export function isValid(formContext: FormContext): boolean {
    return formContext &&
        formContext.meta &&
        formContext.meta.value &&
        formContext.meta.value.valid
        ? formContext.meta.value.valid
        : false;
}

function map(_values: any) {
    if (_values) {
        const touchedMap = _.cloneDeep(_values);
        Object.keys(touchedMap).forEach(function (key, index) {
            touchedMap[key] = true;
        });
        return touchedMap;
    } else return {};
}
