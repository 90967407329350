import { AppStore, store } from "@/store/app";


/**
 * Retrieve the feathers app injected in the server renderer as context
 * Only executable on Server, throw error in client
 * @return Feathers Application
 */
export function retrieveStore() : { store: AppStore } {
    return { store };
}