import { Tags } from "@/feathers/services/tags/tags.class";
import { Ref, ref, watch } from "vue";
import { useSocketIO } from "./use-socket";
import { Tag } from "@/models/app/business/tags.model";
import { createGlobalState } from "@vueuse/core";
import { useStored } from "./use-stored";
import _ from "lodash";
import { useAlert } from "./services/alert/use-alert";
import { useLocale } from "./use-locale";
import useAdminMode from "./use-admin-mode";
import * as XLSX from 'xlsx';

export interface ITags {
    tags: string[];
}

export const useTags = createGlobalState(() => {
    const { pass } = useAdminMode();
    const { emit } = useSocketIO();
    const { locale } = useLocale();
    const { error, success } = useAlert();
    const { data } = useStored<any>('v-filemanager');
    const _tags = ref<{ EN: string[], FR: string[]; }>(data.tags);
    const tags: Ref<string[]> = ref(_tags.value ? _tags.value[(locale.value as 'EN' | 'FR')] : []);

    const currents: Ref<number[]> = ref([]);
    const currentsTagSlugs: Ref<string[]> = ref([]);

    watch(currents, async (value) => {
        console.log('currents', value);
        await getFilteredSlug(tags.value);
    });

    watch(locale, (value: string) => {
        if (_tags.value && value)
            tags.value = _tags.value[value as 'FR' | 'EN'];
    });

    const getTags = (tags: string[]) => {
        const filteredTags = tags.filter((tag, index) => currents.value.includes(index));
        return filteredTags;
    };

    const getFilteredSlug = async (tags: string[]) => {
        const selectedTags = getTags(tags);
        const result = await emit('find', 'api/tags', { slug: { $ne: 'default' }, ['tags.' + locale.value]: { $in: selectedTags } });
        const slugs = result.map((r: Tag) => r.slug);
        currentsTagSlugs.value = _.uniq(slugs);
        return slugs;
    };

    const upload = () => {
        console.log('upload');
        
        const parseCSV = (file: File) => {
            const reader = new FileReader();
            reader.onload = function (event) {
                if (!event?.target?.result) return;
                const lines = (event.target.result as string).split('\n');
                const result = lines.map(line => line.split(';'));
                result.shift();
                send(result);
            };
            reader.readAsText(file, 'UTF-8');
        };

        const parseXLSX = (file: File) => {
            const reader = new FileReader();
            reader.onload = function (event) {
                if (!event?.target?.result) return;
                const data = new Uint8Array(event.target.result as ArrayBuffer);
                const workbook = XLSX.read(data, { type: 'array' });
                const worksheet = workbook.Sheets[workbook.SheetNames[0]];
                const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
                const result = jsonData.map((row: any) => row.map((cell: any) => cell.toString()));  
                result.shift();
                send(result);
            };
            reader.readAsArrayBuffer(file);
        };

        const send = async (result: string[][]) => {
            try {
                const tagMap = new Map();
                const allEN = [], allFR = [];
                for (const row of result) {
                    if (_.isEmpty(row[0]) || _.isEmpty(row[1]) || _.isEmpty(row[2])) continue;
                    const slugs = row[2].split(',').map((slug: string) => slug.trim().replaceAll('"', ''));
                    for (const slug of slugs) {
                        const newTag = {
                            slug: slug,
                            tags: {
                                EN: [row[1].trim().replaceAll('"', '')],
                                FR: [row[0].trim().replaceAll('"', '')],
                            }
                        };
                        const existingTag = tagMap.get(slug);
                        if (existingTag) {
                            existingTag.tags.EN = _.uniq([...existingTag.tags.EN, ...newTag.tags.EN]);
                            existingTag.tags.FR = _.uniq([...existingTag.tags.FR, ...newTag.tags.FR]);
                        } else {
                            tagMap.set(slug, newTag);
                        }
                        allEN.push(...newTag.tags.EN);
                        allFR.push(...newTag.tags.FR);
                    }
                }
                const arrTags = Array.from(tagMap.values());
                const defaultTag = {
                    slug: 'default',
                    tags: {
                        EN: _.uniq(allEN) as string[],
                        FR: _.uniq(allFR) as string[],
                    }
                };
                arrTags.push(defaultTag);

                await emit('create', 'api/tags', arrTags, { pass: pass.value });
                await success('File uploaded successfully');
                await getFilteredSlug(tags.value);
            } catch (err: any) {
                console.error(err);
                await error('An error occurred while uploading the file: ' + err.message);
                throw err;
            }
        };

        const openFile = () => {
            const input = document.createElement('input');
            input.type = 'file';
            input.accept = ' .xlsx, .csv';
            input.onchange = (event: any) => {
                if (!event.target?.files) return;
                const file = event.target.files[0];
                const extension = file.name.split('.').pop()?.toLowerCase();
                if (extension === 'csv') {
                    parseCSV(file);
                } else if (extension === 'xlsx') {
                    parseXLSX(file);
                } else {
                    console.error('Unsupported file format');
                    error('Unsupported file format');
                }
            };
            input.click();
        };

        openFile();
    };

    const reset = () => {
        currents.value = [];
    }

    return {
        tags,
        currents,
        currentsTagSlugs,
        getFilteredSlug,
        getTags,
        upload,
        reset
    };

});

