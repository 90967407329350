import { isSSR } from '@/helpers';
import { MDCSnackbar } from '@material/snackbar';
import { type } from 'os';
import { AppContext, getCurrentInstance, onMounted } from 'vue';
import renderComponent from '../../materials/render-component';



enum AlertTypes {
  SUCCESS = 'success',
  ERROR = 'error',
  INFO = 'info'
}

let destroyComp: any = null
/**
 * alertServuce
 * @param type alert type enum SUCCESS/ERROR
 * @param message alert message
 * @param appContext getCurrentInstance of Vue
 */
export function useAlert() {
  
  const { appContext } = getCurrentInstance() || { appContext: undefined };

  async function alert(type: AlertTypes, message: string) {
    if (!isSSR()) {
      if (appContext) {
        const alert = document.querySelector(".alert");
        destroyComp?.()
        destroyComp = renderComponent({
          el: alert,
          component: (await import('./VSnackbar.vue')).default,
          props: {
            type: type,
            msg: message,
          },
          appContext,
        })
      } else {
        console.log("NO CONTEXT", appContext);
      }
    }
  }

  return {
    info: async (message: string) => {
      return await alert(AlertTypes.INFO, message); 
    },
    error: async (message: string) => {
      return await alert(AlertTypes.ERROR, message); 
    },
    success: async (message: string) => {
      return await alert(AlertTypes.SUCCESS, message); 
    }
  }
}




