import { ref } from "vue";
import { useSocketIO } from "./use-socket";
import { useInputDialog } from "./services/dialog/input-dialog/use-input-dialog";
import { useAlert } from "./services/alert/use-alert";
import { useI18n } from "vue-i18n";
import { createGlobalState, useSessionStorage, useStorage } from "@vueuse/core";
import { useLanguages } from "./use-languages";
// import { usePreviewDisplay } from "./use-preview-display";


export default createGlobalState(() => {
    const { t } = useI18n();
    const { error, success } = useAlert();
    const dialog = useInputDialog();
    const { emit } = useSocketIO();
    const isAdmin = useSessionStorage('smart-screen-is-admin', false);
    const pass = useSessionStorage('smart-screen-admin-pass', '');
    const { currentLanguage } = useLanguages();

    const authAdmin = async (password: string) => {
        pass.value = password;
        try {
            const authResult = await emit('create', 'api/admin/verify', { pass: password });
            isAdmin.value = authResult;
        } catch (err: any) {
            isAdmin.value = false;
            throw err;
        }
    };

    const openAdminModal = () => {
        if (isAdmin.value) {
            isAdmin.value = false;
            pass.value = '';
            success(t('ADMIN_MODE_DEACTIVATED'));
        } else {
            dialog.open({
                title: 'UNLOCK_ADMIN_MODE',
                text: 'ADMIN_MODE_PASSWORD',
                valid: 'Login',
            }, async (password: string) => {
                authAdmin(password).then(() => {
                    success(t('ADMIN_MODE_ACTIVATED'));
                }).catch(() => {
                    error(t('ADMIN_MODE_ERROR'));
                });
            });
        }
    };

    const removeFile = (path: string) => {
        dialog.open({
            title: 'DELETE_FILE',
            text: 'DELETE_FILE_CONFIRM',
            valid: 'Delete',
        }, async (password: string) => {
            authAdmin(password).then(async () => {
                const splitted = path.split('/');
                for (const lang of currentLanguage.languages) {
                    splitted[splitted.length - 3] = lang;
                    const newPath = splitted.join('/');
                    await emit('remove', '/api/file/delete', '', { path: newPath, pass: pass.value });
                }
                success(t('FILE_DELETED'));
                window.location.reload();
            }).catch(() => {
                error(t('ADMIN_MODE_ERROR'));
            });
        });
    };

    return {
        pass,
        isAdmin,
        authAdmin,
        removeFile,
        openAdminModal,
    };
});