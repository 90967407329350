import { User } from "@/models/app/business/user.model";
import { store } from "@/store/app";


/**
 * Retrieve the Logged User in the store from the auth() composable called in serv()
 * Only executable on Server, throw error in client
 * @return Logged User
 */
export function retrieveUser() : { user: User } {
    return { user: store.get('user') };
}