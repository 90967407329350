import { Application } from "@/feathers/declarations";
import { isSSR } from "@/helpers";
import { ISSRContext } from "@/models/app/core/ssr-context.interface";
import { NextFunction, Request, Response } from 'express';
import { useSSRContext } from "vue";
import { retrieveParams } from './retrieve-params';
import { retrieveStore } from "./retrieve-store";
/**
 * Bundle Server Side Rendering Utils for views
 * Only work on server, throw an error if executed on client
 * @return fapp: feather Application, params: Queries parameters from Express
 */
export function bundleSSRContext(): ISSRContext {
  if (isSSR()) {
    const ctx = useSSRContext() as { fapp: Application, req: Request, res: Response, next: NextFunction };
    const { fapp, req, res, next } = ctx;
    const { params } = retrieveParams(ctx);
    const { store } = retrieveStore();
    return { fapp, params, store, req, res, next };
  } else {
    throw new Error("[APP] impossible to Bundle App on client")
  }
}