import { createGlobalState } from "@vueuse/core";
import _, { replace } from "lodash";
import { UnwrapNestedRefs, defineEmits, reactive, ref } from "vue";

export const useFolderOpened = createGlobalState((): {
	currentOpened: UnwrapNestedRefs<{ opened: string[]; }>;
	toggle(path: string): void;
	relativePath(path: string): string;
} => {
	const currentOpened = reactive<{ opened: string[]; }>({
		opened: [],
	});

	const toggle = (path: string): void => {
		let isOpened = false;

		console.log("on retrieve");
		console.log(currentOpened.opened);

		if (currentOpened && currentOpened.opened && currentOpened.opened.length > 0 && currentOpened.opened.includes(path)) {
			isOpened = true;
		}

		if (isOpened) {
			currentOpened.opened = currentOpened.opened.filter((o) => o != path);
		} else {
			console.log("on add");
			currentOpened.opened.push(path);
			console.log(currentOpened.opened);
		}
	};

	const relativePath = (path: string): string => {
		let toReturn: string = path;

		const explPath = path.split("/");

		if (explPath && explPath.length > 4) {
			const sanitized: string[] = [];

			for (let i = 4; i < explPath.length; i++) {
				sanitized.push(explPath[i]);
			}

			toReturn = sanitized.join("/");
		}

		return toReturn;
	};

	return {
		currentOpened,
		toggle,
		relativePath,
	};
});
