export interface ICodeIsoLanguage {
	codeIso: string;
	name: string;
	name_en: string;
	nativeName: string;
}

export const languages_map: ICodeIsoLanguage[] = [
	{ codeIso: "aa", name: "Afar", nativeName: "Afaraf", name_en: "Afar" },
	{
		codeIso: "ab",
		name: "Abkhaze",
		nativeName: "Аҧсуа",
		name_en: "Abkhazian",
	},
	{
		codeIso: "ae",
		name: "Avestique",
		nativeName: "Avesta",
		name_en: "Avestan",
	},
	{
		codeIso: "af",
		name: "Afrikaans",
		nativeName: "Afrikaans",
		name_en: "Afrikaans",
	},
	{ codeIso: "ak", name: "Akan", nativeName: "Akan", name_en: "Akan" },
	{
		codeIso: "am",
		name: "Amharique",
		nativeName: "አማርኛ",
		name_en: "Amharic",
	},
	{
		codeIso: "an",
		name: "Aragonais",
		nativeName: "Aragonés",
		name_en: "Aragonese",
	},
	{ codeIso: "ar", name: "Arabe", nativeName: "العربية", name_en: "Arabic" },
	{
		codeIso: "as",
		name: "Assamais",
		nativeName: "অসমীয়া",
		name_en: "Assamese",
	},
	{
		codeIso: "av",
		name: "Avar",
		nativeName: "авар мацӀ ; магӀарул мацӀ",
		name_en: "Avaric",
	},
	{
		codeIso: "ay",
		name: "Aymara",
		nativeName: "Aymar aru",
		name_en: "Aymara",
	},
	{
		codeIso: "az",
		name: "Azéri",
		nativeName: "Azərbaycan dili",
		name_en: "Azerbaijani",
	},
	{
		codeIso: "ba",
		name: "Bachkir",
		nativeName: "башҡорт теле",
		name_en: "Bashkir",
	},
	{
		codeIso: "be",
		name: "Biélorusse",
		nativeName: "Беларуская",
		name_en: "Belarusian",
	},
	{
		codeIso: "bg",
		name: "Bulgare",
		nativeName: "български език",
		name_en: "Bulgarian",
	},
	{ codeIso: "bh", name: "Bihari", nativeName: "भोजपुरी", name_en: "Bihari" },
	{
		codeIso: "bi",
		name: "Bichelamar",
		nativeName: "Bislama",
		name_en: "Bislama",
	},
	{
		codeIso: "bm",
		name: "Bambara",
		nativeName: "Bamanankan",
		name_en: "Bambara",
	},
	{ codeIso: "bn", name: "Bengali", nativeName: "বাংলা", name_en: "Bengali" },
	{
		codeIso: "bo",
		name: "Tibétain",
		nativeName: "བོད་ཡིག",
		name_en: "Tibetan",
	},
	{
		codeIso: "br",
		name: "Breton",
		nativeName: "Brezhoneg",
		name_en: "Breton",
	},
	{
		codeIso: "bs",
		name: "Bosnien",
		nativeName: "Bosanski jezik",
		name_en: "Bosnian",
	},
	{
		codeIso: "ca",
		name: "Catalan",
		nativeName: "Català",
		name_en: "Catalan, Valencian",
	},
	{
		codeIso: "ce",
		name: "Tchétchène",
		nativeName: "нохчийн мотт",
		name_en: "Chechen",
	},
	{
		codeIso: "ch",
		name: "Chamorro",
		nativeName: "Chamoru",
		name_en: "Chamorro",
	},
	{
		codeIso: "co",
		name: "Corse",
		nativeName: "Corsu ; lingua corsa",
		name_en: "Corsican",
	},
	{ codeIso: "cr", name: "Cri", nativeName: "ᓀᐦᐃᔭᐍᐏᐣ", name_en: "Cree" },
	{
		codeIso: "cs",
		name: "Tchèque",
		nativeName: "Česky ; čeština",
		name_en: "Czech",
	},
	{
		codeIso: "cu",
		name: "Vieux-slave",
		nativeName: "Словѣньскъ",
		name_en: "Church Slavonic, Old Slavonic, Old Church Slavonic",
	},
	{
		codeIso: "cv",
		name: "Tchouvache",
		nativeName: "чӑваш чӗлхи",
		name_en: "Chuvash",
	},
	{ codeIso: "cy", name: "Gallois", nativeName: "Cymraeg", name_en: "Welsh" },
	{ codeIso: "da", name: "Danois", nativeName: "Dansk", name_en: "Danish" },
	{
		codeIso: "de",
		name: "Allemand",
		nativeName: "Deutsch",
		name_en: "German",
	},
	{
		codeIso: "dv",
		name: "Maldivien",
		nativeName: "ދިވެހި",
		name_en: "Divehi, Dhivehi, Maldivian",
	},
	{
		codeIso: "dz",
		name: "Dzongkha",
		nativeName: "རྫོང་ཁ",
		name_en: "Dzongkha",
	},
	{ codeIso: "ee", name: "Ewe", nativeName: "Ɛʋɛgbɛ", name_en: "Ewe" },
	{
		codeIso: "el",
		name: "Grec",
		nativeName: "Ελληνικά",
		name_en: "Greek",
	},
	{
		codeIso: "en",
		name: "Anglais",
		nativeName: "English",
		name_en: "English",
	},
	{
		codeIso: "eo",
		name: "Espéranto",
		nativeName: "Esperanto",
		name_en: "Esperanto",
	},
	{
		codeIso: "es",
		name: "Espagnol",
		nativeName: "Español; castellano",
		name_en: "Spanish, Castilian",
	},
	{
		codeIso: "et",
		name: "Estonien",
		nativeName: "Eesti keel",
		name_en: "Estonian",
	},
	{ codeIso: "eu", name: "Basque", nativeName: "Euskara", name_en: "Basque" },
	{ codeIso: "fa", name: "Persan", nativeName: "فارسی", name_en: "Persian" },
	{ codeIso: "ff", name: "Peul", nativeName: "Fulfulde", name_en: "Fulah" },
	{
		codeIso: "fi",
		name: "Finnois",
		nativeName: "Suomen kieli",
		name_en: "Finnish",
	},
	{
		codeIso: "fj",
		name: "Fidjien",
		nativeName: "Vosa Vakaviti",
		name_en: "Fijian",
	},
	{
		codeIso: "fo",
		name: "Féroïen",
		nativeName: "Føroyskt",
		name_en: "Faroese",
	},
	{
		codeIso: "fr",
		name: "Français",
		nativeName: "Français ; langue française",
		name_en: "French",
	},
	{
		codeIso: "fy",
		name: "Frison occidental",
		nativeName: "Frysk",
		name_en: "Western Frisian",
	},
	{
		codeIso: "ga",
		name: "Irlandais",
		nativeName: "Gaeilge",
		name_en: "Irish",
	},
	{
		codeIso: "gd",
		name: "Écossais",
		nativeName: "Gàidhlig",
		name_en: "Gaelic, Scottish Gaelic",
	},
	{
		codeIso: "gl",
		name: "Galicien",
		nativeName: "Galego",
		name_en: "Galician",
	},
	{
		codeIso: "gn",
		name: "Guarani",
		nativeName: "Avañe'ẽ",
		name_en: "Guarani",
	},
	{
		codeIso: "gu",
		name: "Gujarati",
		nativeName: "ગુજરાતી",
		name_en: "Gujarati",
	},
	{ codeIso: "gv", name: "Mannois", nativeName: "Ghaelg", name_en: "Manx" },
	{ codeIso: "ha", name: "Haoussa", nativeName: "هَوُسَ", name_en: "Hausa" },
	{ codeIso: "he", name: "Hébreu", nativeName: "עברית", name_en: "Hebrew" },
	{
		codeIso: "hi",
		name: "Hindi",
		nativeName: "हिन्दी ; हिंदी",
		name_en: "Hindi",
	},
	{
		codeIso: "ho",
		name: "Hiri motu",
		nativeName: "Hiri Motu",
		name_en: "Hiri Motu",
	},
	{
		codeIso: "hr",
		name: "Croate",
		nativeName: "Hrvatski",
		name_en: "Croatian",
	},
	{
		codeIso: "ht",
		name: "Créole haïtien",
		nativeName: "Kreyòl ayisyen",
		name_en: "Haitian, Haitian Creole",
	},
	{
		codeIso: "hu",
		name: "Hongrois",
		nativeName: "magyar",
		name_en: "Hungarian",
	},
	{
		codeIso: "hy",
		name: "Arménien",
		nativeName: "Հայերեն",
		name_en: "Armenian",
	},
	{
		codeIso: "hz",
		name: "Héréro",
		nativeName: "Otjiherero",
		name_en: "Herero",
	},
	{
		codeIso: "ia",
		name: "Interlingua",
		nativeName: "Interlingua",
		name_en: "Interlingua (International Auxiliary Language Association)",
	},
	{
		codeIso: "id",
		name: "Indonésien",
		nativeName: "Bahasa Indonesia",
		name_en: "Indonesian",
	},
	{
		codeIso: "ie",
		name: "Occidental",
		nativeName: "Interlingue",
		name_en: "Interlingue, Occidental",
	},
	{ codeIso: "ig", name: "Igbo", nativeName: "Igbo", name_en: "Igbo" },
	{
		codeIso: "ii",
		name: "Yi",
		nativeName: "ꆇꉙ",
		name_en: "Sichuan Yi, Nuosu",
	},
	{
		codeIso: "ik",
		name: "Inupiak",
		nativeName: "Iñupiaq ; Iñupiatun",
		name_en: "Inupiaq",
	},
	{ codeIso: "io", name: "Ido", nativeName: "Ido", name_en: "Ido" },
	{
		codeIso: "is",
		name: "Islandais",
		nativeName: "Íslenska",
		name_en: "Icelandic",
	},
	{
		codeIso: "it",
		name: "Italien",
		nativeName: "Italiano",
		name_en: "Italian",
	},
	{
		codeIso: "iu",
		name: "Inuktitut",
		nativeName: "ᐃᓄᒃᑎᑐᑦ",
		name_en: "Inuktitut",
	},
	{
		codeIso: "ja",
		name: "Japonais",
		nativeName: "日本語 (にほんご)",
		name_en: "Japanese",
	},
	{
		codeIso: "jv",
		name: "Javanais",
		nativeName: "Basa Jawa",
		name_en: "Javanese",
	},
	{
		codeIso: "ka",
		name: "Géorgien",
		nativeName: "ქართული",
		name_en: "Georgian",
	},
	{ codeIso: "kg", name: "Kikongo", nativeName: "KiKongo", name_en: "Kongo" },
	{
		codeIso: "ki",
		name: "Kikuyu",
		nativeName: "Gĩkũyũ",
		name_en: "Kikuyu, Gikuyu",
	},
	{
		codeIso: "kj",
		name: "Kuanyama",
		nativeName: "Kuanyama",
		name_en: "Kuanyama, Kwanyama",
	},
	{
		codeIso: "kk",
		name: "Kazakh",
		nativeName: "Қазақ тілі",
		name_en: "Kazakh",
	},
	{
		codeIso: "kl",
		name: "Groenlandais",
		nativeName: "Kalaallisut ; kalaallit oqaasii",
		name_en: "Kalaallisut, Greenlandic",
	},
	{
		codeIso: "km",
		name: "Khmer",
		nativeName: "ភាសាខ្មែរ",
		name_en: "Central Khmer",
	},
	{ codeIso: "kn", name: "Kannada", nativeName: "ಕನ್ನಡ", name_en: "Kannada" },
	{
		codeIso: "ko",
		name: "Coréen",
		nativeName: "한국어 (韓國語) ; 조선말 (朝鮮語)",
		name_en: "Korean",
	},
	{ codeIso: "kr", name: "Kanouri", nativeName: "Kanuri", name_en: "Kanuri" },
	{
		codeIso: "ks",
		name: "Cachemiri",
		nativeName: "कश्मीरी ; كشميري",
		name_en: "Kashmiri",
	},
	{
		codeIso: "ku",
		name: "Kurde",
		nativeName: "Kurdî ; كوردی",
		name_en: "Kurdish",
	},
	{ codeIso: "kv", name: "Komi", nativeName: "коми кыв", name_en: "Komi" },
	{
		codeIso: "kw",
		name: "Cornique",
		nativeName: "Kernewek",
		name_en: "Cornish",
	},
	{
		codeIso: "ky",
		name: "Kirghiz",
		nativeName: "кыргыз тили",
		name_en: "Kirghiz, Kyrgyz",
	},
	{
		codeIso: "la",
		name: "Latin",
		nativeName: "Latine ; lingua latina",
		name_en: "Latin",
	},
	{
		codeIso: "lb",
		name: "Luxembourgeois",
		nativeName: "Lëtzebuergesch",
		name_en: "Luxembourgish, Letzeburgesch",
	},
	{ codeIso: "lg", name: "Ganda", nativeName: "Luganda", name_en: "Ganda" },
	{
		codeIso: "li",
		name: "Limbourgeois",
		nativeName: "Limburgs",
		name_en: "Limburgan, Limburger, Limburgish",
	},
	{
		codeIso: "ln",
		name: "Lingala",
		nativeName: "Lingála",
		name_en: "Lingala",
	},
	{ codeIso: "lo", name: "Lao", nativeName: "ພາສາລາວ", name_en: "Lao" },
	{
		codeIso: "lt",
		name: "Lituanien",
		nativeName: "Lietuvių kalba",
		name_en: "Lithuanian",
	},
	{
		codeIso: "lu",
		name: "Luba",
		nativeName: "tshiluba",
		name_en: "Luba-Katanga",
	},
	{
		codeIso: "lv",
		name: "Letton",
		nativeName: "Latviešu valoda",
		name_en: "Latvian",
	},
	{
		codeIso: "mg",
		name: "Malgache",
		nativeName: "Fiteny malagasy",
		name_en: "Malagasy",
	},
	{
		codeIso: "mh",
		name: "Marshallais",
		nativeName: "Kajin M̧ajeļ",
		name_en: "Marshallese",
	},
	{
		codeIso: "mi",
		name: "Maori de Nouvelle-Zélande",
		nativeName: "Te reo Māori",
		name_en: "Maori",
	},
	{
		codeIso: "mk",
		name: "Macédonien",
		nativeName: "македонски јазик",
		name_en: "Macedonian",
	},
	{
		codeIso: "ml",
		name: "Malayalam",
		nativeName: "മലയാളം",
		name_en: "Malayalam",
	},
	{
		codeIso: "mn",
		name: "Mongol",
		nativeName: "Монгол",
		name_en: "Mongolian",
	},
	{
		codeIso: "mo",
		name: "Moldave",
		nativeName: "лимба молдовеняскэ",
		name_en: "Moldavian",
	},
	{ codeIso: "mr", name: "Marathi", nativeName: "मराठी", name_en: "Marathi" },
	{
		codeIso: "ms",
		name: "Malais",
		nativeName: "Bahasa Melayu ; بهاس ملايو",
		name_en: "Malay",
	},
	{ codeIso: "mt", name: "Maltais", nativeName: "Malti", name_en: "Maltese" },
	{ codeIso: "my", name: "Birman", nativeName: "ဗမာစာ", name_en: "Burmese" },
	{
		codeIso: "na",
		name: "Nauruan",
		nativeName: "Ekakairũ Naoero",
		name_en: "Nauru",
	},
	{
		codeIso: "nb",
		name: "Norvégien bokmål",
		nativeName: "Norsk bokmål",
		name_en: "Norwegian Bokmål",
	},
	{
		codeIso: "nd",
		name: "Sindebele",
		nativeName: "isiNdebele",
		name_en: "North Ndebele",
	},
	{
		codeIso: "ne",
		name: "Népalais",
		nativeName: "नेपाली",
		name_en: "Nepali",
	},
	{ codeIso: "ng", name: "Ndonga", nativeName: "Owambo", name_en: "Ndonga" },
	{
		codeIso: "nl",
		name: "Néerlandais",
		nativeName: "Nederlands",
		name_en: "Dutch, Flemish",
	},
	{
		codeIso: "nn",
		name: "Norvégien nynorsk",
		nativeName: "Norsk nynorsk",
		name_en: "Norwegian Nynorsk",
	},
	{
		codeIso: "no",
		name: "Norvégien",
		nativeName: "Norsk",
		name_en: "Norwegian",
	},
	{
		codeIso: "nr",
		name: "Nrebele",
		nativeName: "Ndébélé",
		name_en: "South Ndebele",
	},
	{
		codeIso: "nv",
		name: "Navajo",
		nativeName: "Diné bizaad ; Dinékʼehǰí",
		name_en: "Navajo, Navaho",
	},
	{
		codeIso: "ny",
		name: "Chichewa",
		nativeName: "ChiCheŵa ; chinyanja",
		name_en: "Chichewa, Chewa, Nyanja",
	},
	{
		codeIso: "oc",
		name: "Occitan",
		nativeName: "Occitan",
		name_en: "Occitan",
	},
	{
		codeIso: "oj",
		name: "Ojibwé",
		nativeName: "ᐊᓂᔑᓈᐯᒧᐎᓐ",
		name_en: "Ojibwa",
	},
	{
		codeIso: "om",
		name: "Oromo",
		nativeName: "Afaan Oromoo",
		name_en: "Oromo",
	},
	{ codeIso: "or", name: "Oriya", nativeName: "ଓଡ଼ିଆ", name_en: "Oriya" },
	{
		codeIso: "os",
		name: "Ossète",
		nativeName: "Ирон ӕвзаг",
		name_en: "Ossetian, Ossetic",
	},
	{
		codeIso: "pa",
		name: "Pendjabi",
		nativeName: "ਪੰਜਾਬੀ ; پنجابی",
		name_en: "Punjabi, Panjabi",
	},
	{ codeIso: "pi", name: "Pali", nativeName: "पािऴ", name_en: "Pali" },
	{
		codeIso: "pl",
		name: "Polonais",
		nativeName: "Polski",
		name_en: "Polish",
	},
	{
		codeIso: "ps",
		name: "Pachto",
		nativeName: "پښتو",
		name_en: "Pashto, Pushto",
	},
	{
		codeIso: "pt",
		name: "Portugais",
		nativeName: "Português",
		name_en: "Portuguese",
	},
	{
		codeIso: "qu",
		name: "Quechua",
		nativeName: "Runa Simi ; Kichwa",
		name_en: "Quechua",
	},
	{
		codeIso: "rc",
		name: "Créole Réunionnais",
		nativeName: "Kréol Rénioné",
		name_en: "Reunion Creole",
	},
	{
		codeIso: "rm",
		name: "Romanche",
		nativeName: "Rumantsch grischun",
		name_en: "Romansh",
	},
	{ codeIso: "rn", name: "Kirundi", nativeName: "kiRundi", name_en: "Rundi" },
	{
		codeIso: "ro",
		name: "Roumain",
		nativeName: "Română",
		name_en: "Romanian, Moldavian, Moldovan",
	},
	{
		codeIso: "ru",
		name: "Russe",
		nativeName: "русский язык",
		name_en: "Russian",
	},
	{
		codeIso: "rw",
		name: "Kinyarwanda",
		nativeName: "Kinyarwanda",
		name_en: "Kinyarwanda",
	},
	{
		codeIso: "sa",
		name: "Sanskrit",
		nativeName: "संस्कृतम्",
		name_en: "Sanskrit",
	},
	{ codeIso: "sc", name: "Sarde", nativeName: "sardu", name_en: "Sardinian" },
	{
		codeIso: "sd",
		name: "Sindhi",
		nativeName: "सिन्धी ; سنڌي، سندھی",
		name_en: "Sindhi",
	},
	{
		codeIso: "se",
		name: "Same du Nord",
		nativeName: "Davvisámegiella",
		name_en: "Northern Sami",
	},
	{
		codeIso: "sg",
		name: "Sango",
		nativeName: "Yângâ tî sängö",
		name_en: "Sango",
	},
	{
		codeIso: "sh",
		name: "Serbo-croate",
		nativeName: "srpskohrvatski jezik ; српскохрватски језик",
		name_en: "Serbo-Croatian",
	},
	{
		codeIso: "si",
		name: "Cingalais",
		nativeName: "සිංහල",
		name_en: "Sinhala, Sinhalese",
	},
	{
		codeIso: "sk",
		name: "Slovaque",
		nativeName: "Slovenčina",
		name_en: "Slovak",
	},
	{
		codeIso: "sl",
		name: "Slovène",
		nativeName: "Slovenščina",
		name_en: "Slovenian",
	},
	{
		codeIso: "sm",
		name: "Samoan",
		nativeName: "Gagana fa'a Samoa",
		name_en: "Samoan",
	},
	{ codeIso: "sn", name: "Shona", nativeName: "chiShona", name_en: "Shona" },
	{
		codeIso: "so",
		name: "Somali",
		nativeName: "Soomaaliga ; af Soomaali",
		name_en: "Somali",
	},
	{
		codeIso: "sq",
		name: "Albanais",
		nativeName: "Shqip",
		name_en: "Albanian",
	},
	{
		codeIso: "sr",
		name: "Serbe",
		nativeName: "српски језик",
		name_en: "Serbian",
	},
	{ codeIso: "ss", name: "Swati", nativeName: "SiSwati", name_en: "Swati" },
	{
		codeIso: "st",
		name: "Sotho du Sud",
		nativeName: "seSotho",
		name_en: "Southern Sotho",
	},
	{
		codeIso: "su",
		name: "Soundanais",
		nativeName: "Basa Sunda",
		name_en: "Sundanese",
	},
	{
		codeIso: "sv",
		name: "Suédois",
		nativeName: "Svenska",
		name_en: "Swedish",
	},
	{
		codeIso: "sw",
		name: "Swahili",
		nativeName: "Kiswahili",
		name_en: "Swahili",
	},
	{ codeIso: "ta", name: "Tamoul", nativeName: "தமிழ்", name_en: "Tamil" },
	{
		codeIso: "te",
		name: "Télougou",
		nativeName: "తెలుగు",
		name_en: "Telugu",
	},
	{
		codeIso: "tg",
		name: "Tadjik",
		nativeName: "тоҷикӣ ; toğikī ; تاجیکی",
		name_en: "Tajik",
	},
	{ codeIso: "th", name: "Thaï", nativeName: "ไทย", name_en: "Thai" },
	{
		codeIso: "ti",
		name: "Tigrigna",
		nativeName: "ትግርኛ",
		name_en: "Tigrinya",
	},
	{
		codeIso: "tk",
		name: "Turkmène",
		nativeName: "Türkmen ; Түркмен",
		name_en: "Turkmen",
	},
	{
		codeIso: "tl",
		name: "Tagalog",
		nativeName: "Tagalog",
		name_en: "Tagalog",
	},
	{
		codeIso: "tn",
		name: "Tswana",
		nativeName: "seTswana",
		name_en: "Tswana",
	},
	{
		codeIso: "to",
		name: "Tongien",
		nativeName: "faka Tonga",
		name_en: "Tonga (Tonga Islands)",
	},
	{ codeIso: "tr", name: "Turc", nativeName: "Türkçe", name_en: "Turkish" },
	{
		codeIso: "ts",
		name: "Tsonga",
		nativeName: "xiTsonga",
		name_en: "Tsonga",
	},
	{
		codeIso: "tt",
		name: "Tatar",
		nativeName: "татарча ; tatarça ; تاتارچا",
		name_en: "Tatar",
	},
	{ codeIso: "tw", name: "Twi", nativeName: "Twi", name_en: "Twi" },
	{
		codeIso: "ty",
		name: "Tahitien",
		nativeName: "Reo Mā`ohi",
		name_en: "Tahitian",
	},
	{
		codeIso: "ug",
		name: "Ouïghour",
		nativeName: "Uyƣurqə ; ئۇيغۇرچ",
		name_en: "Uighur, Uyghur",
	},
	{
		codeIso: "uk",
		name: "Ukrainien",
		nativeName: "українська мова",
		name_en: "Ukrainian",
	},
	{ codeIso: "ur", name: "Ourdou", nativeName: "اردو", name_en: "Urdu" },
	{
		codeIso: "uz",
		name: "Ouzbek",
		nativeName: "O'zbek ; Ўзбек ; أۇزبېك",
		name_en: "Uzbek",
	},
	{ codeIso: "ve", name: "Venda", nativeName: "tshiVenḓa", name_en: "Venda" },
	{
		codeIso: "vi",
		name: "Vietnamien",
		nativeName: "Tiếng Việt",
		name_en: "Vietnamese",
	},
	{
		codeIso: "vo",
		name: "Volapük",
		nativeName: "Volapük",
		name_en: "Volapük",
	},
	{ codeIso: "wa", name: "Wallon", nativeName: "Walon", name_en: "Walloon" },
	{ codeIso: "wo", name: "Wolof", nativeName: "Wollof", name_en: "Wolof" },
	{ codeIso: "xh", name: "Xhosa", nativeName: "isiXhosa", name_en: "Xhosa" },
	{
		codeIso: "yi",
		name: "Yiddish",
		nativeName: "ייִדיש",
		name_en: "Yiddish",
	},
	{ codeIso: "yo", name: "Yoruba", nativeName: "Yorùbá", name_en: "Yoruba" },
	{
		codeIso: "za",
		name: "Zhuang",
		nativeName: "Saɯ cueŋƅ ; Saw cuengh",
		name_en: "Zhuang, Chuang",
	},
	{
		codeIso: "zh-CN",
		name: "Chinois",
		nativeName: "中文, 汉语, 漢語",
		name_en: "Chinese",
	},
	{ codeIso: "zu", name: "Zoulou", nativeName: "isiZulu", name_en: "Zulu" },
	{ codeIso: "en-GB", name: "Anglais (Britannique)", nativeName: "English (British)", name_en: "English (British)" },
	{ codeIso: "es-ES", name: "Espagnol", nativeName: "Espanol (Espana)", name_en: "Spanish" },
	{ codeIso: "pt-PT", name: "Portugais (Portugal)", nativeName: "", name_en: "Portuguese (Portugal)" },
	{ codeIso: "pt-BR", name: "Portugais (Brésil)", nativeName: "", name_en: "Portuguese (Brasil)" },
	{ codeIso: "sr-CS", name: "Serbe", nativeName: "", name_en: "Serbian" },
	{ codeIso: "sv-SE", name: "Suédois", nativeName: "", name_en: "Swedish" },
	{ codeIso: "zh-HK", name: "Chinois (Hong Kong)", nativeName: "", name_en: "Chinese (Hong Kong)" },
	{ codeIso: "zh-TW", name: "Chinois (Taiwan)", nativeName: "", name_en: "Chinese (Taiwan)" },
];
