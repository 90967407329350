import { createI18n } from 'vue-i18n'
import { isSSR } from './helpers';
import local_en from './locales/en';
import local_fr from './locales/fr';


export const i18n = createI18n({
  legacy: false,
  locale: 'EN',
  fallbackLocale: 'EN',
  allowComposition: true, // you need to specify that!
  messages: {
    EN: local_en,
    FR: local_fr
  }
})

console.log('[VUE i18n]' ,i18n.allowComposition); // output is true