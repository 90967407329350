import { loadingConfiguration } from "@/vue/theme/vue-loader-config";
import _ from "lodash";
import { ActiveLoader, Props, Slots, useLoading } from "vue-loading-overlay";


export function useLoader(globalProps?: Props | undefined, globalSlots?: Slots | undefined) {
    const $loading = useLoading(_.merge(loadingConfiguration.props, globalProps), _.merge(loadingConfiguration.slots, globalSlots));
    let loader: ActiveLoader | undefined = undefined;

    function show(props?: Props | undefined, slots?: Slots | undefined) {
        loader = $loading.show(props, slots);
        return loader;
    }

    function hide() {
        if(loader) {
            loader.hide();
        }
    }

    return {
        $loading,
        loader,
        show,
        hide,
    }
}