import { FilesManagerNode } from "@/feathers/services/file-manager/file-manager.class";
import _, { replace } from "lodash";
import { defineEmits, reactive, Ref, ref } from "vue";
import { ILanguage } from "./use-languages";
import { ISelect, ISelectable } from "./use-multi-select";
import { MODE } from "./use-modes";
import { createGlobalState } from "@vueuse/core";
import { useStored } from "./use-stored";
import { User } from "@/models/app/business/user.model";


// use this interface to declare template data
interface IData {
	files: FilesManagerNode;
	currentUser: User;
	tags: string[];
}


export const useDatas = createGlobalState(() => {

	const { data } = useStored<IData>("v-filemanager");
	const originalDatas = reactive<ISelectable>({
		items: null,
	});
	originalDatas.items = data?.files?.children || null;
	


	return {
		data,
		originalDatas,
	};
});
